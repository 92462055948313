export const pageview = (url: string) => {
  if (typeof window.gtag === 'function' && process.env.GA_TRACKING_ID) {
    window.gtag('config', process.env.GA_TRACKING_ID, {
      page_path: url,
    });
  }
};
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
type Event = {
  action: string;
  category?: string;
  label?: string;
  value?: number;
};

export const event = ({ action, category, label, value }: Event) => {
  if (typeof window.gtag === 'function' && process.env.GA_TRACKING_ID) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
};

const externalLink = (label: string, value: number) => {
  event({
    action: 'click',
    category: 'External Links',
    label: label,
    value: value,
  });
};

export const track = {
  externalLink,
};
