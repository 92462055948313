import { createContext } from 'react';

export type AudioArgs = {
  isAudioOn: boolean;
  setIsAudioOn: (isOn: boolean) => void;
};

const AudioContext = createContext<AudioArgs>({
  isAudioOn: false,
  setIsAudioOn: () => {},
});

export default AudioContext;
