import { theme, ITheme } from '@chakra-ui/core';

import icons from './icons';

interface CustomColors {
  brand: {
    text: string;
    deepSeaGreen: string;
    parisDaisy: string;
    ripeLemon: string;
    texasYellow: string;
    oldLaceYellow: string;
    alabasterGrey: string;
    desertStormGrey: string;
    mysticGrey: string;
    heatherGrey: string;
    whiteRockGrey: string;
  };
}
export interface CustomTheme extends ITheme {
  colors: ITheme['colors'] & CustomColors;
}

export const rem = (px: string) => {
  return `${parseInt(px, 10) / 16}rem`;
};

const spacing = {
  '0': '0',
  '1': rem('8px'),
  '2': rem('12px'),
  '3': rem('16px'),
  '4': rem('24px'),
  '5': rem('32px'),
  '6': rem('48px'),
  '8': rem('56px'),
  '10': rem('64px'),
  '12': rem('80px'),
  '13': rem('96px'),
  '14': rem('120px'),
  '15': rem('164px'),
};

const customTheme: CustomTheme = {
  borders: theme.borders,
  breakpoints: [rem('768px')],
  colors: {
    ...theme.colors,
    brand: {
      text: '#062E40',
      deepSeaGreen: '#0D4A64',
      parisDaisy: '#FFEF6D',
      ripeLemon: '#F4D109',
      texasYellow: '#F9EF9A',
      oldLaceYellow: '#FDFAE7',
      alabasterGrey: '#F7F7F7',
      desertStormGrey: '#F5F5F3',
      mysticGrey: '#ECF1F3',
      heatherGrey: '#B7C9D0',
      whiteRockGrey: '#F0EDDF',
    },
  },
  fonts: {
    body: '"Open Sans", sans-serif',
    heading: '"Libre Baskerville", serif',
    mono: 'monospace',
  },
  fontSizes: {
    xs: rem('12px'),
    sm: rem('15px'),
    md: rem('17px'),
    lg: rem('18px'),
    xl: rem('19px'),
    '2xl': rem('22px'),
    '3xl': rem('28px'),
    '4xl': rem('35px'),
    '5xl': rem('38px'),
    '6xl': rem('50px'),
  },
  fontWeights: theme.fontWeights,
  icons: icons,
  letterSpacings: theme.letterSpacings,
  lineHeights: theme.lineHeights,
  opacity: theme.opacity,
  radii: {
    ...theme.radii,
    sm: rem('4px'),
  },
  shadows: theme.shadows,
  sizes: {
    ...theme.sizes,
    ...spacing,
  },
  space: {
    ...theme.space,
    ...spacing,
  },
  zIndices: theme.zIndices,
};

export default customTheme;
